globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"d93df5d9e688a1a4175a7b302d833f7c2d3acc09"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/operator/assets/production-20241009095757-d93df5d9e688a1a4175a7b302d833f7c2d3acc09";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.sentryDsn,
  enabled: process.env.NODE_ENV !== 'test',
  release: process.env.sentryRelease,
  ignoreErrors: [],
});
